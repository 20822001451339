.input-wrap {
  &--select {
    @apply relative;

    select {
      appearance:none;
      -webkit-appearance:none;
    }

    &:after {
      font-family: "tours";
      content: "\e96c";
      @apply absolute right-2 top-1/2 transform -translate-y-1/2;
    }
  }
}


button > * {
  pointer-events: none!important;
}
