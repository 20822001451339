@font-face {
    font-family: "marcopolo";
    src: url('fonts/marcopolo.eot?t=1690730386070'); /* IE9*/
    src: url('fonts/marcopolo.eot?t=1690730386070#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url("fonts/marcopolo.woff2?t=1690730386070") format("woff2"),
    url("fonts/marcopolo.woff?t=1690730386070") format("woff"),
    url('fonts/marcopolo.ttf?t=1690730386070') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url('fonts/marcopolo.svg?t=1690730386070#marcopolo') format('svg'); /* iOS 4.1- */
    font-display: swap;
  }

  [class^="icon-"], [class*=" icon-"] {
    font-family: 'marcopolo' !important;
    font-style: normal;
    line-height: .8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-lg { font-size: 1.3333em; line-height: 0.75em; vertical-align: -.0667em; }
  .icon-xl { font-size: 1.5em; line-height: 0.6666em; vertical-align: -.075em; }
  .icon-xxs { font-size: .5em; }
  .icon-xs { font-size: .75em; }
  .icon-sm { font-size: .875em }
  .icon-1x { font-size: 1em; }
  .icon-2x { font-size: 2em; }
  .icon-3x { font-size: 3em; }
  .icon-4x { font-size: 4em; }
  .icon-5x { font-size: 5em; }
  .icon-6x { font-size: 6em; }
  .icon-7x { font-size: 7em; }
  .icon-8x { font-size: 8em; }
  .icon-9x { font-size: 9em; }
  .icon-10x { font-size: 10em; }
  .icon-fw { text-align: center; width: 1.25em; }

  .icon-menu-3-line:before { content: "\ef34"; }
  .icon-search-line:before { content: "\f0d1"; }
  .icon-arrow-right-s-fill:before { content: "\ea6d"; }
  .icon-arrow-left-s-fill:before { content: "\ea63"; }

  .icon-star-s-fill:before { content: "\f18c"; }
  .icon-run-line:before { content: "\f0a5"; }
  .icon-government-line:before { content: "\edd9"; }
  .icon-map-pin-2-line:before { content: "\ef0a"; }
  .icon-phone-line:before { content: "\efec"; }
  .icon-whatsapp-line:before { content: "\f2bc"; }
  .icon-mail-line:before { content: "\eef6"; }
  .icon-map-pin-time-line:before { content: "\ef18"; }
  .icon-arrow-down-s-fill:before { content: "\ea4d"; }
  .icon-mail-send-line:before { content: "\eefc"; }
  .icon-door-open-line:before { content: "\ec4c"; }
  .icon-subtract-line:before { content: "\f1af"; }
  .icon-add-line:before { content: "\ea13"; }
  .icon-close-line:before { content: "\eb99"; }
  .icon-page-separator:before { content: "\efbd"; }
  .icon-sort-asc:before { content: "\f15f"; }
  .icon-heart-3-line:before { content: "\ee0b"; }
  .icon-image-2-line:before { content: "\ee45"; }
  .icon-share-forward-line:before { content: "\f0fd"; }
  .icon-checkbox-blank-circle-fill:before { content: "\eb7c"; }
  .icon-price-tag-3-line:before { content: "\f023"; }
  .icon-shield-flash-line:before { content: "\f105"; }
  .icon-shield-user-line:before { content: "\f10c"; }
  .icon-wifi-line:before { content: "\f2c0"; }
  .icon-temp-cold-line:before { content: "\f1f2"; }
  .icon-parking-line:before { content: "\efd0"; }
  .icon-temp-hot-line:before { content: "\f1f4"; }
  .icon-plant-line:before { content: "\f007"; }
  .icon-layout-top-2-line:before { content: "\ee9f"; }
  .icon-macbook-line:before { content: "\eee8"; }
  .icon-layout-top-line:before { content: "\eea1"; }
  .icon-sun-line:before { content: "\f1bf"; }
  .icon-building-line:before { content: "\eb0f"; }
  .icon-shield-keyhole-line:before { content: "\f107"; }
  .icon-wheelchair-line:before { content: "\f2be"; }
  .icon-compass-3-line:before { content: "\ebbe"; }
  .icon-fullscreen-line:before { content: "\ed9c"; }
  .icon-question-line:before { content: "\f045"; }
  .icon-thumb-up-line:before { content: "\f207"; }
  .icon-wallet-line:before { content: "\f2ae"; }
  .icon-close-circle-line:before { content: "\eb97"; }
  .icon-heart-line:before { content: "\ee0f"; }
  .icon-double-quotes-l:before { content: "\ec51"; }
  .icon-double-quotes-r:before { content: "\ec52"; }
  .icon-secure-payment-line:before { content: "\f0d3"; }
