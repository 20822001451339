.richText {
    font-size: 1rem;
    line-height: 1.55;

    @media (min-width: 567px) {
      font-size: 1.15rem;
    }

    h3,
    h4,
    h5,
    h6 {
        line-height: 1.5;
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.4rem;
    }

    strong, b {
      @apply text-blue;
    }

    img {
        height: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 100% !important;
    }

    p,
    ul {
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0.5rem;
    }

    &--intro {

        p {
            font-size: 1.125rem;
            line-height: 1.5;
            margin-bottom: 1rem;

            &:first-child {
                font-size: 1.3125rem;
                font-weight: 600;
            }
        }
    }

}
