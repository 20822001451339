@tailwind base;

@import 'scss/iconfont';
@import 'scss/index';

@import 'scss/base/typo';
@import 'scss/base/forms';

@import 'scss/components/nav';
@import 'scss/components/card';
@import 'scss/components/modal';
@import 'scss/components/loader';
@import 'scss/components/form';
@import 'scss/components/dropdown';
@import 'scss/components/accordion';
@import 'scss/components/hero';


@tailwind components;

@tailwind utilities;
