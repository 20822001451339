button[disabled] {
  cursor: not-allowed;
  opacity: 0.8;
}

#i_surname {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    user-select: none;
}

input.formFieldError {
  @apply border-red;
}

label.error {
    display: none !important;
    @apply text-red;
}

input.formFieldError+label.error {
    display: block !important;
}

[data-validate-for],
.too-many {
    @apply text-red;
    margin-top: 0.5rem;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 600;
}
