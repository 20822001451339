html {
  scroll-behavior: smooth;
}

body {
  @apply font-sans text-primary;
  -webkit-font-smoothing: antialiased;
}

h2 {
  @apply text-3xl lg:text-5xl font-semibold;
}

.z-100 {
  z-index: 100;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.js--spinner:after{
  content:none;
  display:inline-block;
  vertical-align:middle;
  margin-left:.4em;
  height:1em;
  width:1em;
  animation:oc-rotate-loader .8s infinite linear;
  border:.2em solid currentColor;
  border-right-color:transparent;
  border-radius:50%;
  opacity:.5;
  filter:alpha(opacity=50)
}
.oc-loading .js--spinner:after{
  content:''
}

.container {
  @apply px-8;
  @media screen and (max-width: 767px) {
    @apply px-5;
  }
}

::selection {
  @apply bg-orange-dark bg-opacity-30;
}

.home-header {
  @media screen and (min-width: 1024px) {
    height: calc(100vh - 200px);
  }
}

.active-pag {
  @apply bg-blue-dark text-white;
  border-color: #0078B8 !important;
}

.inactive {
  @apply opacity-20;
  cursor: default !important;
}

.counter-btn.disable {
  @apply cursor-default shadow-none border border-gray-alt text-gray-alt bg-transparent;
}

.card-hover {
  &:hover {
      .bg-black {
        @apply bg-opacity-70
      }
      .card-link {
        @apply bg-blue
      }
  }
}

.check-select {
  .icon-close-line {
    display: none;
  }
  input:checked + .check-wrapper {
    @apply bg-blue text-white;
    .icon-close-line {
      display: block;
    }
    .icon-add-line {
      display: none;
    }
  }
}

.check-custom {
  @apply relative cursor-pointer select-none pl-4 block;
  input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
  }
  .checkmark {
    @apply absolute top-0.5 left-0 h-5 w-5 border-2 rounded-sm;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 8px;
      height: 13px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  input:checked + .checkmark:after {
    @apply block
  }

}

.check-circle-radio {
  input:checked + .checkwrapper {
    @apply bg-primary;
    .checktitle {
      @apply text-white;
    }
    .checkmark {
        @apply bg-orange-dark border-orange-dark;
    }
  }
}

.check-circle-location {
  .checkmark {
    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 9999px
    }
    path {
      fill: #D9D9D9;
    }
  }
  input:checked + .checkwrapper {
    .checkmark:after {
      background: #000;
    }
    path {
      fill: #6BBCF9;
    }
  }
}
