.c-Dropdown {
  position: relative;

    &__trigger {
      cursor: pointer;
    }

    &__content {
      @apply bg-white;
      padding: 0;
      -webkit-overflow-scrolling: touch;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      display: none;
      transition: opacity 0s linear, visibility 0s linear 0s;
      box-shadow: none;
      top: 4rem;
      right: 0;
      z-index: 30;
      width: 16rem;
      box-shadow: 0 0 30px rgba(0, 0, 0, .2);

      @media (min-width: 567px) {
        left: 0;
        right: auto;
      }

      &--full {
        width: 100%;
      }

      &--wide {
        width: 53.5rem;
      }

      &--share {
        padding: 0.5rem 1rem;
      }

      &--mob-top {
          top: auto!important;
          bottom: 2.5rem;
      }

    }

}

.js--dropdown-open {
    .c-Dropdown__content {
      visibility: visible;
      opacity: 1;
      display: block;
      transition: opacity 0s linear;
    }

    .icon-dropdown {
      transform: rotate(-180deg);
      transform-origin: center;
    }
}
