body.modal-open {
  overflow: hidden;
}

.modal {
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    transition: all .4s cubic-bezier(.47, .17, .17, .58);

    &__close {
        @apply text-gray;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        height: 2.05rem;
        width: 2.05rem;
        line-height: 1.875rem;
        font-size: 0.875rem;
        text-align: center;
        border: 2px solid;
        border-radius: 50%;
        transition: color .4s cubic-bezier(.47, .17, .17, .58);

        &:hover,
        &:focus {
            @apply text-black;
            cursor: pointer;
        }
    }

    &__window {
        @apply bg-white;
        position: relative;
        width: 95%;
        margin: 1rem auto;
        padding: 3rem 1.5rem 3rem;
        transform: scale(0);
        opacity: 0;
        transition: all ease .4s;

        @media (min-width: 567px) {

          &--sm {
              max-width: 25rem;
              padding: 3rem 0;
          }

            padding: 3rem;
            width: 50%;
            margin: 3rem auto;
        }

        &--lg {
          @apply rounded-xl;

            @media (min-width: 567px) {
              width: 85%;
              max-width: 70rem;
            }
        }

        &--full {
            margin: auto;
            height: 100vh;
            border-radius: 0!important;

            @media (min-width: 567px) {
              width: 100%;
            }
        }

    }

    &__footer {
      &--fixed {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      }
    }

    &.open {
        display: block;
        visibility: visible!important;
        pointer-events: all;
        z-index: 99;
        opacity: 1;

        .modal__window {
          transform: scale(1);
          opacity: 1;
        }
    }
}
