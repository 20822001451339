.ajax-overlay {
    @apply bg-white bg-opacity-90;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    transition: opacity ease 0.3s;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.oc-loading .ajax-overlay {
    opacity: 1;
    z-index: 10;
    visibility: visible;
}
/*
*    Loader
*/
.loader {
    @apply text-blue;
    -webkit-animation: rotate 1s infinite;
    animation: rotate 1s infinite;
    height: 40px;
    left: 50%;
    z-index: 10;
    margin-left: -20px;
    margin-top: -20px;
    pointer-events: none;
    position: absolute;
    top: 50px;
    transition: opacity 0.3s ease-in-out;
    width: 40px;
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

.filter {
    &__btn--apply {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        width: calc(100% + 3rem);
        border-top-left-radius: 0!important;
    }
}
