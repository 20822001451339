.main-nav {

  &__parent {
    @apply relative;

    .overlay {
        @apply rounded-lg;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        position: relative;
        visibility: hidden;
        opacity: 0;
        display: none;
        width: 100%;
        padding-top: 0;
        padding-bottom: 1rem;
        transition: opacity 0s linear, visibility 0s linear 0s;
        box-shadow: none;

        @media (min-width: 1200px) {
            @apply bg-white text-primary;
            position: absolute;
            box-shadow: 0 0 30px rgba(0, 0, 0, .2);
            padding:  0;
            top: 2.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 14rem;
        }


    }

    &.open {

      .overlay {
          visibility: visible;
          opacity: 1;
          display: block;
          transition: opacity 0s linear;
      }

    }

  }
}


.modal-open {
  .js-toggle-menu {
    .menu-icon {
      display: none!important;
    }
    .close-icon {
      display: block!important;
    }
  }
}
