.page {

    &-hero {
        line-height: 0;

        img {
            height: 70vh;
            width: 100%;
            object-fit: cover;
        }

        &__header {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            bottom: 0;
            margin: 0;
            z-index: 4;
        }


    }

}
